<template>
    <div class="recursive-folder-container"
         :class="{'folder' : ! isRoot, 'open' : open}"
         v-if="level < 4 && myFolder">

        <button class="btn btn-link folder-title" v-if="! isRoot" @click.prevent.stop="open = ! open">
            <i class="fa small " :class="{'fa-chevron-down' : open, 'fa-chevron-right' : ! open}"/>
            {{ myFolder.name }}
        </button>
        <div class="folder-contents"
             :style="folderContentsStyle"
             :class="'level-'+level+' folder-id-'+id">


            <div
                v-for="topic in myPagedTopics"
                class="topic-item text-truncate"
                :class="{
                    'is-current-topic'  : hasSuggestions.type == 'topic' && currentTopic.id === topic.id,
                    'not-current-topic' : hasSuggestions.type == 'topic' && currentTopic.id !== topic.id,
                }"
                :key="topic.id+'-topic-list'"
                @click.prevent.stop="setTopic(topic)"
            >

                <topics-svg class="singular em-1 me-1 top-1"
                            v-if="currentTopic"/>


                <search-highlighter :text="topic.name" :search-text="searchText"/>
            </div>

            <paginator :extra-small="true"
                       :stay-on-first-page="true"
                       :items="myFilteredTopics"
                       @update="setPagedTopics"
                       :size="15"/>

            <div :class="{'child-folders' : ! isRoot}">
                <recursive-sidebar-folder
                    :folder-id="childFolder.id"
                    v-for="childFolder in myChildren"
                    :key='"sub-folder-"+childFolder.id'
                    :level="nextLevel"
                    @containsCurrentTopic="childContainsCurrentTopic"
                    :search-text="searchText"
                />
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import RecursiveSidebarFolder from './RecursiveFolderContainer'
import uuid from 'uuid';
import Paginator from '@/utilities/Paginator'
import {highlightSearchText} from "@/services/SearchHighlighter";
import SearchHighlighter from "../Controls/SearchHighlighter";
import TopicsSvg from "@/assets/svg/topics";


export default {
    name: 'recursive-sidebar-folder',
    props: ['folderId', 'isRoot', 'level', 'searchText', 'TopicsSvg '],
    components: {
        TopicsSvg,
        SearchHighlighter,
        RecursiveSidebarFolder, Paginator
    },
    data() {
        return {
            open: this.isRoot,
            id: uuid.v4(),
            myPagedTopics: [],
        }
    },
    watch: {
        currentTopic() {
            this.checkForCurrentTopic();
        }
    },
    computed: {
        ...mapGetters({
            folders: 'folders',
            topics: 'topics',
            currentTopic: 'currentTopic',
            accountTeamMembers: 'accountTeamMembers',
            user: 'currentUser',
            hasSuggestions: 'hasSuggestions',
        }),
        myFolder() {
            if (this.folderId) {
                let folder = this.folders.find(f => this.folderId.includes(f.id));
                return folder
            }

        },
        myChildren() {
            let children = this.folders.filter(f => f.parents.find(p => p.includes(this.myFolder.id)));
            return this.$sort.alphabetize(children);
        },
        myTopics() {
            return this.$sort.alphabetize(
                this.topics.filter(t => {
                    return t.folder.includes(this.folderId) || this.folderId.includes(t.folder)
                })
            );
        },
        myFilteredTopics() {
            return this.myTopics.filter(t => {
                if (this.searchText) {
                    return t.name.toLowerCase().includes(this.searchText.toLowerCase())
                }
                return true;
            })
        },
        nextLevel() {
            return this.level + 1;
        },
        canCreateTopics() {
            return this.$accessControl.can(this.user.role, 'createTopics');
        },
        folderContentsStyle() {
            let maxHeight = this.open ? 'inherit' : '0px';
            return {
                maxHeight
            }
        }
    },
    mounted() {
        this.checkForCurrentTopic();
    },
    methods: {
        topicName(name) {
            return highlightSearchText(name, this.searchText);
        },
        setPagedTopics(topics) {
            console.log('* RecursiveFolderContainer')
            this.myPagedTopics = topics;
        },
        checkForCurrentTopic() {
            if (this.currentTopic && this.myTopics.find(t => t.id == this.currentTopic.id)) {
                this.childContainsCurrentTopic();
            }
        },
        childContainsCurrentTopic() {
            this.open = true;
            this.$emit('containsCurrentTopic');
        },
        setTopic(topic) {
            console.log('* RecursiveFolderContainer.vue setTopic about to setCurrentTopic')
            this.$store.dispatch('setCurrentTopic', {topic});
            if (this.$router.currentRoute.path !== '/')
                this.$router.push("/");
            window.Events.$emit('NavigatedToSuggestion');
            // NOTE(casio): this was causing 'vue-router.esm.js:2065 Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "/".'  - remove?
            // this.$router.push("/");
        },
        searchFocus() {
            this.$emit('searchFocus')
        },
        searchBlur(event) {
            if (event.relatedTarget && event.relatedTarget.classList.contains('clear-search-text')) {
                event.target.focus();
            } else {
                this.$emit('searchBlur')
            }
        }
    }
}
</script>
<style lang="scss">
// Do not scope.
.feature-flag-off .recursive-folder-container {
    .is-current-topic {
        padding: 0 !important;
        margin: 0 !important;
        font-weight: var(--font-weight-bold);
        background-color: transparent !important;
        color: var(--steel-0) !important;
        border-radius: var(--border-radius);

    }
}
</style>
<style lang="scss" scoped>
.is-current-topic {
    padding: 0 4px;
    margin-left: -4px;
    margin-right: -4px;
    font-weight: var(--font-weight-semibold);
    background-color: var(--primary-50);
    color: var(--bs-light) !important;
    border-radius: var(--border-radius);
}

.not-current-topic {
    color: var(--steel-0);
}

.folder-contents .folder-contents {
    padding-left: 1rem;
}
</style>
