<template>
    <div>
        <div v-if="busy">
            <!-- We're loading. Show something here or no? -->
        </div>
        <div v-else>
            <div class="d-flex justify-content-center mt-5" >
                <div style="max-width: 670px;" class=" mx-auto">
                    <div v-if="collection">
                        <h3>This Collection has no suggestions to display currently.</h3>
                        <p>Adjust the filtering options or add a suggestion to a Collection by clicking the button at the bottom right of each suggestion card.</p>
                        <div class="img my-3 img-fluid img-empty-collection" :style="{'height' : '13rem'}">
                            <span class="mg-fluid"></span>
                        </div>
                        <p class="text-center">Or, you can <a @mouseout="hideTheArrow()" @mouseover="showTheArrow()"
                                                         href="#"
                                           @click="showAddUrlModal()">add suggestions via url</a>.</p>
                        <div class="img img-fluid mx-5 img-suggestion-url" :style="{'height' : '7rem'}">
                            <span></span>
                        </div>
                    </div>
                    <div v-else>
                        <h3>You have no Collections at the moment.</h3>
                        <p>Add your first Collection here.</p>
                        <!-- <p class="small">What are <a href="#" target="_blank">collections</a>?</p> -->
                        <button @click="showCollectionBuilder()" class="btn btn-outline-danger"><i class="fa fa-plus"></i> New Collection</button>
                    </div>
                    <p class="mb-0 mt-2 text-center"><a href="#" @click.prevent="goToTopics()">Go to my Topics</a></p>
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props : ['busy', 'collection'],
    methods : {
        goToTopics(){
            this.$store.dispatch('setSuggestionViewerType', {type : 'topics'});
        },
        showCollectionBuilder(){
            window.Events.$emit('showCollectionCreationModal');
        },
        showAddUrlModal(){
            window.Events.$emit('showAddUrlToCollectionModal')
        },
        showTheArrow(){
            window.Events.$emit('showAddUrlAttentionGrabber');
        },
        hideTheArrow(){
            window.Events.$emit('hideAddUrlAttentionGrabber');
        }
    },
    computed: {
        ...mapGetters({hearsay: "isHearsay"})
    }
}
</script>

<style>
.img-empty-collection {
    display: flex;
    align-items: center;
    object-fit: contain;
}

.img-empty-collection span {
    background-image: url('@/assets/images/empty-collection.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

@media (max-width: 800px) {
    .img-empty-collection {
        height: 8rem !important;
    }
    .img-empty-collection span {
        background-size: 300px;
    }
}

.img-suggestion-url {
    display: flex;
    align-items: center;
}

.img-suggestion-url span {
    background-image: url('@/assets/images/suggestions_url.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

</style>
