<template>
    <integration-item title="OpenAI for AI Post Text Generation" icon="openai" v-if="features.openaiIntegration && powerUpEnabled('openai')" v-show="powerupSearchMatches('openai open ai machine learning generative')">
        <a @click="editOpenaiApiKey()" class="btn btn-sm btn-primary float-end">{{openaiButtonText}}</a>
        <div class="integration-features">
            <label class="text-muted small text me-2" title="Current API Key:">
                {{openaiApiKeyStatus}}
            </label>
            <label class="text-muted small text me-2" title="Don't have an OpenAI account? Make one here">
                <a>
                    Don't have an OpenAI account?
                    <a :href="learnUrls.openaiApiKey" v-if="learnUrls.openaiApiKey" target="_blank" rel="noopener noreferrer">
                        Learn more
                    </a>
                </a>
            </label>
        </div>
    </integration-item>
</template>

<style scoped lang="scss">

</style>

<script>

import {mapGetters} from 'vuex'
import IntegrationItem from "@/components/Settings/Team/IntegrationItem.vue";
import AccountDataEditor from "@/components/Accounts/AccountDataEditor.vue";
import IntegrationSearch from "@/components/Integrations/IntegrationSearch.vue";

export default {
    name: 'OpenaiIntegrationItem',
    mixins: [AccountDataEditor, IntegrationSearch],
    components: {IntegrationItem},
    data() {
        return {
            openai_api_key: null,
        }
    },
    methods: {
        async editOpenaiApiKey() {
            this.openai_api_key = prompt('Enter OpenAI API key:', '')

            // FIXME: post-MVP: add store step here
            // TODO: DEV-1171 post-MVP do an openai check (can do a rough check - is this the right format at least)
            if (this.openai_api_key !== null){
                await this.saveAccountData();
                await this.loadAccountData();
                // dispatch an action within the accounts store - openaiApiKeyUpdated
            }
        }
    },
    computed:{
        ...mapGetters({
            features: 'accountFeatures',
            learnUrls: 'learnUrls',
        }),
        openaiApiKeyCensored() {
            // formatting OpenAI API key same way as it appears on OpenAI account
            return this.loadedData.openai_api_key ? `${this.loadedData.openai_api_key.slice(0,3)}...${this.loadedData.openai_api_key.slice(-4)}` : '';
        },
        openaiApiKeyStatus() {
            return this.loadedData.openai_api_key ? `Current API key: ${this.openaiApiKeyCensored}` : `No API key`;
        },
        openaiButtonText() {
            return this.loadedData.openai_api_key ? 'Update API key' : 'Add API key'
        },
        // eslint-disable-next-line vue/no-async-in-computed-properties
        defaultValues() {
            return {
                openai_api_key: null,
            }
        }
    },
    created() {
        this.metadataKey = 'openai';
    },
}
</script>
