<template>
    <div>
        <div v-if="busy">
            <!-- We're loading. Show something here or no? -->
        </div>
        <div v-else class="p-3">
            <div class="d-flex justify-content-center mt-5" v-if="stillBuilding">
                <div class="row">
                    <div class="col-md">
                        <h1>One moment while we gather your suggestions</h1>
                        <p>We are searching far and wide to find the best suggestions that meet your search criteria.</p>
                        <div class="delay-message">
                            <h5 class="text-danger">Taking too long?</h5>
                            <p><a href="#" @click.prevent="reload">Refreshing the page</a> can sometimes speed things up.</p>
                        </div>
                    </div>
                    <div class="col-md">
                    <img
                        v-if="hearsay"
                        class="img-fluid"
                        src="/assets/images/whitelabels/hearsay/Topic%20Creation%20gif.gif"
                        alt="Still building topic suggestions. Please wait"
                    />
                    <img
                        v-else-if="everyoneSocial"
                        class="img-fluid"
                        src="@/assets/images/whitelabels/everyone-social/UpContent_Building.gif"
                        alt="Still building topic suggestions. Please wait"
                    />
                    <img
                        v-else
                        class="img-fluid"
                        src="@/assets/images/building.gif"
                        alt="Still building topic suggestions. Please wait" 
                    />
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-center mt-5" v-else>
                <div v-if="showFavorites">
                    <h3>You have no favorited items in this topic.</h3>
                    <p>Use the star button <button class="btn btn-sm btn-outline-primary"><i class="fa fa-star-o"></i></button> to favorite a suggestion and they will appear here.</p>
                </div>
                <div v-else>

                    <div v-if="accountTopics.length == 0" >
                        <h3>Looks like you don't have any topics!</h3>
                        <div v-if=" ! isReviewer">
                            <p>You can create one now, or choose from one of our suggested starters:</p>
                            <default-topics />
                        </div>
                    </div>
                    <div v-else>
                        <h3>This topic has no suggestions at the moment.</h3>
                        <p>Please select a different topic or adjust the sorting options.</p>
                        <p>Having trouble finding suggestions? <a  
                                href="http://learn.upcontent.com/en/articles/3832101-topic-creation"
                                class="small"
                                target="_blank">Learn how to create an effective topic <i class="fa fa-arrow-right"></i>
                            </a>
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import DefaultTopics from '@/components/Topics/DefaultTopics'
import Roles from '@/utilities/Roles'
import {mapGetters} from "vuex";

export default {
    props : ['stillBuilding', 'busy', 'showFavorites', 'accountTopics'],
    mixins : [Roles],
    components : {
        DefaultTopics
    },
    methods : {
        reload(){
            window.location.reload();
        }
    },
    computed: {
        ...mapGetters({
            hearsay: "isHearsay",
            everyoneSocial: "isEveryoneSocial"
        })
    }
}
</script>


<style scoped>

.delay-message {
    animation: 15s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
    0% { opacity:0; }
    95% { opacity:0; }
    100% { opacity:1; }
}

</style>
